define(['app', 'siteObj'], function (app) {
  const personalisationImageSelect = function () {
    const component = {};

    component.config = {
      selectors: {
        carouselContainer: '.personalisation_image_buttons_container',
        carouselImages: '.personalisationImage',
        previousBtn: '.personalisationImageSelect_leftArrow',
        nextBtn: '.personalisationImageSelect_rightArrow',
        designLabel: '#design',
        pageComponent: '[data-component="athenaProductImageCarousel"]',
        productPrice: '[data-component="productPrice"]',
        inputTextBoxes: '.personalisation_overlayTextInput',
        requiredInputTextBox: '.personalisation_overlayTextInput required',
      },
      publishChannels: {
        updateImageOverlayChannel: 'productCarouselImageOverlay/update',
        updateImages: 'productMultipleImages/newImage',
        validPersonalisation: 'personalisation/message',
      },
      dataAttributes: {
        selected: 'data-selected',
        personalisationInput: 'data-personalisation-input',
        previewAssetSetIdentifier: 'data-previewAssetSetIdentifier',
        productId: 'data-product-id',
        displayValue: 'data-display-value',
      },
    };

    component.init = (element) => {
      component.element = element;
      component.previousBtn = component.element.querySelector(component.config.selectors.previousBtn);
      component.nextBtn = component.element.querySelector(component.config.selectors.nextBtn);
      component.carouselImages = component.element.querySelectorAll(component.config.selectors.carouselImages);
      component.persImageWidth = component.element.querySelector('#persImageOptions');
      component.carouselContainer = component.element.querySelector(component.config.selectors.carouselContainer);
      component.designLabel = component.element.querySelector(component.config.selectors.designLabel);
      component.pageComponent = document.querySelector(component.config.selectors.pageComponent);
      component.productPrice = document.querySelector(component.config.selectors.productPrice);
      component.inputTextBoxes = document.querySelectorAll(component.config.selectors.inputTextBoxes);
      component.requiredInputTextBox = document.querySelector(component.config.selectors.requiredInputTextBox);
      component.addEventListeners();
      component.preSelectTemplate();
      return component;
    }

    const urlParams = new URLSearchParams(window.location.search);
    let imageIndex = 0;
    let templateName = '';

    component.addEventListeners = () => {
      component.previousBtn && component.previousBtn.addEventListener('click', component.prevScroll);
      component.nextBtn && component.nextBtn.addEventListener('click', component.nextScroll);
      component.carouselImages && component.carouselImages.forEach((carouselImage) => {
        carouselImage.addEventListener('click', component.persImgSelect)
      })
    };

    component.preSelectTemplate = () => {
      let template = urlParams.get('template');
      const validRegEx = /[^A-Za-z0-9 .-]/gi;
      if(template) {
        template = template.replace(validRegEx, '');
        component.carouselImages.forEach((img, index) => {
          if (img.value.toLowerCase() === template.toLowerCase()) {
            img.checked = true;
            img.attributes[component.config.dataAttributes.selected].nodeValue = 'true';
            img.setAttribute(component.config.dataAttributes.personalisationInput, '');
            component.updateSelectedImage();
            imageIndex = index;
            component.updatePersCarousel(imageIndex);
            component.selectedImageChange(img.value);
          }
        });
      }
    }

    component.imgWidth =() => {
      let imgWidth=0;
      component.persImageWidth && (imgWidth = component.persImageWidth.offsetWidth + 16); //gap width
      return imgWidth;
    }

    component.prevScroll = () => {
      imageIndex = imageIndex-2;
      imageIndex = (imageIndex < -1 ) ? component.carouselImages.length-1 : imageIndex;
      component.updatePersCarousel(imageIndex);
    };

    component.nextScroll = () => {
      imageIndex = imageIndex+2;
      imageIndex = (imageIndex >= component.carouselImages.length) ? 0 : imageIndex;
      component.updatePersCarousel(imageIndex);
    };

    component.updatePersCarousel = (index) => {
      let imgWidth = component.imgWidth();
      component.carouselContainer.scrollLeft = `${imgWidth * index}`;
    }

    component.updateSelectedImage = () => {
      const productID = component.productPrice.attributes['data-product-id'].nodeValue || siteObj.productID;
      component.carouselImages && component.carouselImages.forEach(carouselImage => {

        carouselImage.checked ? (
          (carouselImage.attributes[component.config.dataAttributes.selected].nodeValue = 'true') &&
          (templateName = carouselImage.attributes[component.config.dataAttributes.previewAssetSetIdentifier].nodeValue)
        ) : carouselImage.attributes[component.config.dataAttributes.selected].nodeValue = 'false';
      });

      app.publish(component.config.publishChannels.updateImages, {
        productId: productID,
        variation: false,
        personalised: true,
        templateName: templateName,
      });
    }
    component.selectedImageChange = (currentSelected) => {
      component.carouselImages.forEach((img)=>{
        img.attributes[component.config.dataAttributes.selected].nodeValue=false;
        img.hasAttribute(component.config.dataAttributes.personalisationInput) && img.removeAttribute(component.config.dataAttributes.personalisationInput);

        if(img.value===currentSelected)
        {
          img.attributes[component.config.dataAttributes.selected].nodeValue=true;
          img.setAttribute(component.config.dataAttributes.personalisationInput,'');
          component.designLabel.innerHTML = img.attributes[component.config.dataAttributes.displayValue].nodeValue;
        }

      })
    }
    component.persImgSelect = (e) => {
      component.updateSelectedImage();
      component.selectedImageChange(e.target.value);
    }

    return component;
  };
  return personalisationImageSelect;
});
